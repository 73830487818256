import React from "react"

class AppDownload extends React.Component {
    componentDidMount() {

        function getMobileOperatingSystem() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;

            if (/android/i.test(userAgent)) {
                return "android";
            }

            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "ios";
            }

            return "unknown";
        }
        let os = getMobileOperatingSystem();

        if (os == "android") {
            window.location.replace("https://play.google.com/store/apps/details?id=care.thinkyou.app");
        }else if (os == "ios"){
            window.location.replace("https://apps.apple.com/nl/app/thinkyou/id1528346879");
        }else{
            window.location.replace("https://thinkyou.care");
        }
    }
    render() {
        return null;
    }
}

export default AppDownload;